import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private subject = new Subject<any>();

  get adminURL() {
    return 'administrator';
  }

  base_url() {
    return 'https://savelifeinitiative.com/server/';
    return 'https://192.168.8.105/sali/';
    // return 'https://demo.creativeweb.com.ng/v01/';
    // return 'https://192.168.8.100/oziCommerce/';
    // return 'http://192.168.43.125/oziCommerce/';
  }

  bankInfo() {
    return {
      name: 'Oziconnect Global Network Limited',
      ngn: 2032394126,
      usd: 2032394061,
      bank: 'First Bank Plc',
      swift: 'FBNINGLA',
      instructions: 'For payment confirmation, please send proof of payment to payment@oziconnect.com',
    };
  }

  clearnUrl(str) {
    if (str) {
      return str.replace(/[^a-zA-Z0-9 &+,._-]/g, '').split('&').join('and')
        .split(' + ').join('-').split('+ ').join('-').split('+').join('-')
        .split(', ').join('-').split(',').join('-')
        .split('  ').join('-').split(' - ').join('-').split(' ').join('-')
        .toLowerCase();
    }
  }

  getTitleCase(str) {
    return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase(); });
  }

  getRandomString(lengthCnt) {
    let result = '', i;
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (i = lengthCnt; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigService } from '../config.service';
import { tap, delay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { AdminAuthService } from '../admin-auth.service';

@Injectable({ providedIn: 'root' })
export class GeneralSettingsService {
  private serverUrl: string;
  private token: string;
  private _contents = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private adminAuthService: AdminAuthService
  ) {
    this.serverUrl = this.config.base_url();
    this.adminAuthService.admin.subscribe(auth => {
      if (auth) { this.token = auth.token; }
    });
  }

  get getSettings() {
    return this._contents;
  }

  settings() {
    return this.http.get<any>(this.serverUrl + 'general_settings')
    .pipe(tap(resData => {
        if (resData) { this._contents.next(resData); }
    }));
  }

  updateSettings(postData: string) {
    return this.http.post<any>(
      this.serverUrl + 'general_settings/update/' + this.token,
      {data: postData}
    );
  }

  updateSocial(postData: string) {
    return this.http.post<any>(
      this.serverUrl + 'general_settings/update_social/' + this.token,
      {data: postData}
    );
  }


  updateBank(postData: string) {
    return this.http.post<any>(
      this.serverUrl + 'general_settings/updateBank/' + this.token,
      {data: postData}
    );
  }


  // bank_details_update(data: string, id) {
  //   return this.http.post<any>(
  //     this.serverUrl + this.adminUrl +
  //     '/other_manager/bank_details_update/' + this.token,
  //     {data: data, id: id}
  //   );
  // }


}

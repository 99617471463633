import { Routes } from '@angular/router';
import { AuthGuard } from './data/services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(
      './guest/guest.module'
    ).then(mod => mod.GuestModule)
  },

  {
    path: 'user',
    loadChildren: () => import(
      './user/user.module'
    ).then(mod => mod.UserModule),
    canLoad: [AuthGuard]
  },

  // Admin lazyload does not work with mobile app.
  // So comment it when working on mobile apps
  {
    path: 'administrator',
    loadChildren: () => import(
      './administrator/administrator.module'
    ).then(mod => mod.AdministratorModule)
  },


];

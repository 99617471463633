import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './data/services/auth.service';
import { AdminAuthService } from './data/services/admin-auth.service';
import { SEOService } from './data/services/seo.service';
import { GeneralSettingsService } from './data/services/guest/general-settings.service';
import { HomeSliderService } from './data/services/guest/home-slider.service';
import { CategoryService } from './data/services/guest/category.service';
import { ProductService } from './data/services/guest/products.service';
import { CurrencyService } from './data/services/currency.service';
import { ShoppingCartService } from './data/services/guest/shopping-cart.service';
import { StaticContentService } from './data/services/guest/static-content.service';
import { SocialSettingsService } from './data/services/guest/social-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  generalSettings: any;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private authService: AuthService,
    private adminAuthService: AdminAuthService,
    private seoService: SEOService,
    private generalSettingsService: GeneralSettingsService,
    private homeSliderService: HomeSliderService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private currencyService: CurrencyService,
    private shoppingCartService: ShoppingCartService,
    private staticContentService: StaticContentService,
    private socialSettingsService: SocialSettingsService,
  ) {}

  ngOnInit() {
    this.checkAuth();
    this.getCurrency();
    this.loadSettings();
    this.seoUpdate();
    this.updateUserAuth();
    this.updateAdminAuth();
    this.homeItems();
    this.getPageContents();
    this.authService.autoLogin().subscribe();
  }

  private updateUserAuth() {
    // To-do
  }

  private updateAdminAuth() {
    // To-do
  }
  
  private loadSettings() {
    this.getSocialLink();
    // this.generalSettingsService.settings().subscribe();
    this.generalSettingsService.settings().subscribe(res => {
      if (res) {
        this.generalSettings = res;
        // Set favicon
        if (res.favicon_url) {
          this._document.getElementById('appFavicon').setAttribute('href', res.favicon_url);
        }
      }
      this.seoUpdate();
    });
  }

  private getSocialLink() {
    this.socialSettingsService.settings().subscribe();
  }

  private seoUpdate() {
    const title = this.generalSettings ?
      this.generalSettings.title : 'Welcome';

    const description = this.generalSettings ?
      this.generalSettings.description : 'Welcome';

    const keywords = this.generalSettings ?
      this.generalSettings.keywords : 'Welcome';

    this.seoService.updateTitle('Welcome');
    // this.seoService.updateOgUrl('my title');
    this.seoService.updateDescription('Welcome');
    this.seoService.updateKeyword(keywords);
  }

  private homeItems() {
    this.homeSliderService.sliders().subscribe();
    this.productService.deal(10).subscribe();
    this.productService.featured(12).subscribe();
    this.productService.featuredCategories(4).subscribe();
  }

  private getCurrency() {
    this.currencyService.currencyDefault().subscribe();
  }

  private getShoppingCart() {
    this.shoppingCartService.shoppingCart().subscribe();
  }

  private checkAuth() {
    this.authService.autoLogin().subscribe();
  }

  private getPageContents() {
    this.staticContentService.contents().subscribe();
  }

}

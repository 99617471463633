import { Component, OnInit, HostListener } from '@angular/core';

import { CategoryService } from '@src/app/data/services/guest/category.service';
import { ConfigService } from '@src/app/data/services/config.service';
import { ActivatedRoute } from '@angular/router';

import { HomeSliderService } from '@src/app/data/services/guest/home-slider.service';
import { GeneralSettingsService } from '@src/app/data/services/guest/general-settings.service';
import { SocialSettingsService } from '@src/app/data/services/guest/social-settings.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  allSocail: any;
  isShow: boolean;
  topPosToStartShowing = 100;
  category: string;
  currYear: number;
  aboutUs: any;
  webSet: any;

  // SCROLL TO TOP
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    console.log('[scroll]', scrollPosition);
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  constructor(
    private categoryService: CategoryService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private homeSliderService: HomeSliderService,
    private generalSettingsService: GeneralSettingsService,
    private socialService: SocialSettingsService
  ) { }

  ngOnInit() {
    this.category = this.route.snapshot.paramMap.get('category');
    this.getCategory();
    this.getHomeSliders();
    this.getWebSettings();
    const date = new Date();
    this.currYear = date.getFullYear();
    this.getSocialLink();
  }

  private getSocialLink() {
    this.socialService.getSettings.subscribe(res => {
      if (res) {
        this.allSocail = res;
      }
    });
  }

  private getHomeSliders() {
    this.homeSliderService.sliders().subscribe(res => {
      if (res) {
        this.aboutUs = res.banners;
      }
    });
  }

  private getCategory() {
    this.categoryService.getCategory.subscribe(res => {
      this.category = res;
    });
  }

  private getWebSettings() {
    this.generalSettingsService.getSettings.subscribe(res => {
      if (res) {
        this.webSet = res;
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@src/app/shared/ui/header/header.component';
import { FooterComponent } from '@src/app/shared/ui/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SummaryPipe } from '@src/app/shared/summary.pipe';
import { SafePipe } from '@src/app/shared/safe.pipe';
import {TimeAgoPipe} from 'time-ago-pipe';
import { DonateComponent } from '@src/app/shared/ui/donate/donate.component';
import { LoadingIndicatorComponent } from '@src/app/shared/ui/loading-indicator/loading-indicator.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SummaryPipe,
    SafePipe,
    TimeAgoPipe,
    DonateComponent,
    LoadingIndicatorComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    LazyLoadImageModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    LazyLoadImageModule,
    SummaryPipe,
    SafePipe,
    TimeAgoPipe,
    DonateComponent,
    LoadingIndicatorComponent
  ],
  entryComponents: [
  ]
})
export class SharedModule { }

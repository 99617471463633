import { Component, OnInit } from '@angular/core';
import { GeneralSettingsService } from '@src/app/data/services/guest/general-settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  webSet: any;
  logo: any;
  toggleNavbar: any;
  
  constructor(
    private generalSettingsService: GeneralSettingsService,
  ) { }

  ngOnInit() {
    this.getWebSettings();
  }

  private getWebSettings() {
    this.generalSettingsService.getSettings.subscribe(res => {
      if(res) {
        this.webSet = res;
        if(this.webSet.logo_url){
          this.logo = this.webSet.logo_url;
        }
      }
    });
  }

}
